<template>
    <div class="login-container d-flex align-items-center">
        <div class="form-login text-center">
            <i class="fa fa-envelope-open-text fa-3x"></i>
            <h3 class="fw-bold text-black">Check your email</h3>
            <small class="text-muted">We have sent password recovery instructions to your mail if it is valid</small>
            <div class="mt-3 mb-3">
                <small class="text-muted"><router-link to="/login">Skip, I'll confirm later</router-link></small>
            </div>
            <small class="text-muted">
                Didn't receive an email? Check your spam folder or
                <router-link to="/forgotpassword">send another recovery mail</router-link>
            </small>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PasswordRecovery'
};
</script>

<style scoped></style>
